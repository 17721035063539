<template>
  <div class="notification-alert-slection">
    <div style="position: relative">
      <validation-provider
        #default="validationContext"
        name="SIM number"
        :rules="{
          required: isRequired,
          min: constants.MIN_SIM_NUMBER,
          max: constants.MAX_SIM_NUMBER,
          regex: constants.PHONE_PATTERN
        }"
      >
        <b-form-group
          :label="isRequired ? $t('device.SIMNumber*') : placeholder"
        >
          <b-form-group>
            <b-input-group class="">
              <b-form-input
                name="SIM number1"
                :state="getValidationState(validationContext)"
                v-b-toggle.collapse-1
                v-model="name"
                :placeholder="placeholder"
                @input="(e) => getIcon(e)"
                :class="
                  visible ? 'sim-icon-input collapsed-icons' : 'sim-icon-input'
                "
                :aria-expanded="visible ? 'true' : 'false'"
                aria-controls="collapse-4"
                @click="getIcon(name)"
              ></b-form-input>
            </b-input-group>

            <b-collapse
              id="collapse-4"
              v-model="visible"
              class="sim-icon-modal mb-1 sim-selection-responsive-alert"
            >
              <div class="sim-icon-card">
                <div class="table-responsive" v-if="show">
                  <b-skeleton-table
                    v-if="show"
                    :rows="10"
                    :columns="1"
                    :table-props="{ bordered: true, striped: true }"
                  />
                </div>

                <b-table
                  responsive
                  show-empty
                  sticky-header
                  :items="items"
                  :fields="tableColumns"
                  select-mode="single"
                  ref="table"
                  @row-selected="onRowSelected"
                  v-else
                >
                  <template #cell(select)="row">
                    <b-form-checkbox
                      v-model="row.item.selected"
                      @change="onRowSelected(row.item, !row.item.selected)"
                    ></b-form-checkbox>
                  </template>
                  <template #cell(msisdn)="row">
                    <span @click="onRowSelected(row.item, row.item.selected)"
                      >{{
                        row.item.msisdn
                          ? `${row.item.msisdn}  ${
                              row.item.operator
                                ? `- (${row.item.operator})`
                                : ""
                            }`
                          : ""
                      }}
                    </span>
                  </template>
                </b-table>
              </div>
            </b-collapse>
          </b-form-group>
          <b-form-invalid-feedback>
            {{
              validationContext.errors[0]
                ? handleError(validationContext.errors[0])
                : ""
            }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import simList from "@/views/Sim/SimList.vue";
import {
  BCard,
  BCollapse,
  BButton,
  VBToggle,
  BFormInput,
  BCol,
  BInputGroup,
  BFormGroup,
  BTable,
  BSkeletonTable,
  BFormCheckbox
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import constants from "@/utils/constants";
// Note: Vue automatically prefixes the directive name with 'v-'
import DeviceService from "@/libs/api/device-service";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "iconView",
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    BButton,
    VBToggle,
    BFormInput,

    BCol,

    BInputGroup,
    BFormGroup,
    simList,
    BTable,
    BSkeletonTable,
    BFormCheckbox
  },
  props: [
    "alertName",
    "isEditable",
    "placeholder",
    "operatorKey",
    "simKey",
    "isRequired",
    "value"
  ],

  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      test: "",
      operator: "",
      name: "",
      alertId: "",
      visible: false,
      totalSims: 0,
      oldIcons: [],
      icons: [],
      refFormObserver,
      getValidationState,
      show: false,
      items: [],
      timeoutId: null,
      tableColumns: [
        {
          key: "select",
          tdClass: "select-td",
          thClass: "select-th",
          label: ""
        },
        {
          key: "msisdn",
          tdClass: "first-child",
          thClass: "first-child text-left",
          label: this.$t("sim.msisdn")
        }
      ]
    };
  },
  directives: {
    "b-toggle": VBToggle
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    this.name = this.value;
    this.getAllSims();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  watch: {
    value(val) {
      this.name = val;
    },
    name() {
      this.$emit("setOperator", [
        {
          key: this.operatorKey,
          value: this.operator
        },
        {
          key: this.simKey,
          value: this.name
        }
      ]);
      this.debounce();
    }
  },
  computed: {},
  methods: {
    debounce(func) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllSims();
      }, 500);
    },
    handleOutsideClick(event) {
      const clickedElement = event.target;
      if (!this.$el.contains(clickedElement)) {
        this.visible = false;
      }
    },
    getIcon(e) {
      if (e === "" || !e || (e && !e.trim())) {
        this.icons = this.oldIcons; // lowecase
      }
      this.visible = true;
    },
    selectedAlert(e) {
      this.name = e && e.name;
      this.visible = false;
      this.alertId = e && e.id;
      this.$emit("input", e.id);
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    onRowSelected(selectedRows, flag) {
      if (selectedRows && selectedRows.isNew) {
        if (!flag) {
          this.name = "";
          this.operator = "";
        }
        this.visible = false;
        return;
      }
      this.items =
        this.items.map((e) => {
          if (selectedRows.id === e.id && !flag) {
            e.selected = true;
            this.name = e.msisdn;
            this.operator = e.operator;
            this.$emit("setOperator", [
              {
                key: this.operatorKey,
                value: this.operator
              },
              {
                key: this.simKey,
                value: this.name
              }
            ]);

            this.visible = false;
          } else {
            e.selected = false;
          }

          return e;
        }) || [];
      if (flag) {
        this.name = "";
        this.operator = "";
      }
    },
    async getAllSims(status) {
      try {
        this.show = true;
        const me = this;
        this.filter = [];

        let requestData = {
          page: 1,
          page_size: 100,
          is_allow_child: this.isAllowChild,
          account_id:
            this.$route.query.account_id ||
            localStorage.getItem("USER_ACCOUNT_ID"),
          filters: this.name
            ? [
                {
                  field: "msisdn",
                  operator: "ilike",
                  value: this.name.toString()
                }
              ]
            : []
        };

        let response = await new DeviceService().getSimsNameList(requestData);

        if (response && response.data) {
          this.show = false;
          const sims = response.data.options;
          this.items =
            sims.map((e) => {
              const item = {
                msisdn: e.msisdn,
                operator: e.operator,
                id: e.value,
                selected: false
              };
              return item;
            }) || [];
          const exist = this.items.find((e) => e.msisdn === this.name);
          if (!exist) {
            if (this.name && this.name.length > 5) {
              this.items.push({
                msisdn: this.name + "(Add New)",
                operator: "",
                isNew: true,
                selected: true
              });
            }
          }
          this.totalSims =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    }
  }
};
</script>
<style lang="scss">
.notification-alert-slection {
  .sim-selection-responsive-alert {
    .adduser-btn-row {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.sim-icon-card {
  background-color: var(--white);
  z-index: 9;
  // height: 32vh;
  overflow: auto;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  // margin-bottom: 1rem;
}
.input-prefix-img {
  border: 1px solid var(--gray4);
  height: 37.5px;
  width: 40px;
  border-radius: 7px 0px 0px 7px;
  text-align: center;
  img {
    height: 32px;
    margin: auto;
    padding: 3px;
  }
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 5px;
    }
  }
}
.sim-icon-modal {
  position: absolute;
  z-index: 9;
  padding: $px_10;
  margin-top: 0px;
  min-width: $percent_100;
  min-height: 32vh;
  padding: 5px 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: 0;
}
.sim-image {
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 8px;
      border-radius: initial;
    }
  }
}
</style>
<style>
.table td {
  /* padding: 0rem 2rem !important; */
}
.select-th {
  max-width: 20px;
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.select-td {
  max-width: 20px;
  padding-left: 6px !important;
  padding-right: 6px !important;
}
</style>
