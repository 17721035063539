<template>
  <div>
    <b-card class="mt-36 add-device-align">
      <div
        class="d-flex justify-content-between align-items-center mobile-mb-1 mb-1"
      >
        <div>
          <h4 class="card-title mb-0" v-if="component_key !== 'allocation'">
            {{ $t("device.History") }}
          </h4>
        </div>
        <div class="d-flex align-items-center">
          <div class="" v-if="component_key == 'allocation'">
            <div class="history-al-outer d-hide-s">
              <div class="history-date">
                {{ $t("device.dateRange") }}
              </div>
              <div class="date-mb">
                <DateTimePicker
                  v-model="selectedDate"
                  :placeholder="$t('device.EnterDateRange')"
                  :isEditable="true"
                />
              </div>
              <div>
                <b-form-group>
                  <b-button
                    class="filter-button min-100"
                    variant="primary"
                    @click="callMyApiCheck"
                    >{{ $t("device.search") }}</b-button
                  >
                </b-form-group>
              </div>
              <div>
                <b-form-group>
                  <b-button
                    class="filter-button min-100"
                    variant="outline-primary"
                    @click="clearStartEndDate"
                    >{{ $t("device.Clear") }}</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="" v-if="component_key !== 'allocation'">
            <div class="history-outer d-hide-s">
              <div class="history-date">
                {{ $t("device.dateRange") }}
              </div>
              <div class="date-mb">
                <DateTimePicker
                  v-model="selectedDate"
                  :placeholder="$t('device.EnterDateRange')"
                  :isEditable="true"
                />
              </div>
              <div class="ml-1">
                <b-form-group>
                  <b-button
                    class="filter-button min-100"
                    variant="primary"
                    @click="callMyApiCheck"
                    >{{ $t("device.search") }}</b-button
                  >
                </b-form-group>
              </div>
              <div class="ml-1">
                <b-form-group>
                  <b-button
                    class="filter-button min-100"
                    variant="outline-primary"
                    @click="clearStartEndDate"
                    >{{ $t("device.Clear") }}</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <b-button
              variant="primary"
              size="sm"
              class="desktop-hidden mr-1"
              @click="showhistoryFiltermenu = !showhistoryFiltermenu"
            >
              <feather-icon icon="FilterIcon" size="15" />
            </b-button>
            <b-button
              variant="outline-primary"
              size="sm"
              @click="
                (e) => {
                  $router.push({ name: 'devices-list' });
                }
              "
              v-if="component_key !== 'allocation'"
              class="btn set-back-mobile v2-back ml-1"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
            </b-button>
          </div>
          <div
            class="d-flex flex-wrap filter-card"
            v-if="!showhistoryFiltermenu"
          >
            <div class="alert-per-page align-items-center mr-1">
              <div class="addXIcon">
                <feather-icon
                  class="sizeIcon"
                  @click="showhistoryFiltermenu = !showhistoryFiltermenu"
                  icon="XIcon"
                  size="15"
                />
              </div>
              <b-row class="history-outer mb-0 mt-2">
                <b-col md="5" class="new-date-picker-25">
                  <b-form-group :label="$t('device.dateRange')" class="date-mb">
                    <DateTimePicker
                      v-model="selectedDate"
                      :placeholder="$t('device.EnterDateRange')"
                      :isEditable="true"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <div class="d-flex justify-content-between mt-1">
                    <b-button
                      class="btn-s"
                      variant="primary"
                      @click="callMyApiCheck"
                      >{{ $t("device.search") }}</b-button
                    >

                    <b-button
                      class="btn-s"
                      variant="outline-primary"
                      @click="clearStartEndDate"
                      >{{ $t("device.Clear") }}</b-button
                    >
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>

      <History
        :myHistoryData="allocationHistory"
        :isPaginationVisible="true"
        :currentPage="currentPage"
        :perPage="perPage"
        :component_key="component_key"
        :totalRows="totalDevices"
        :callMyApiPart="callMyApiPart"
        class="scroll-history"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BBadge,
  BTab,
  BTabs
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import DeviceService from "@/libs/api/device-service";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import constants from "@/utils/constants";
import DateTimePicker from "../DynamicComponents/DatePickerForHistory.vue";

import Loader from "@/layouts/components/Loader.vue";
import History from "@/layouts/components/History.vue";
// import flatPickr from "vue-flatpickr-component";
import * as moment from "moment";
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    // flatPickr,
    Loader,
    History,
    BBadge,
    BTab,
    BTabs,
    DateTimePicker
  },
  directives: {
    Ripple
  },
  props: ["deviceData", "component_key"],
  computed: {
    isComplete() {
      return this.device.status !== "ACTIVE";
    }
  },
  data() {
    return {
      showhistoryFiltermenu: true,
      hasErr: "",
      oldDeviceOwnerId: null,
      deviceOwnerId: null,
      showLoading: false,
      allocationHistory: [],
      singleHistory: [],
      device: {},
      constants,
      isEditable: true,
      selectedDate: { startDate: null, endDate: null },
      startDate: null,
      endDate: null,
      currentPage: 14,
      totalDevices: null,
      perPage: 14,
      previousEndDate: null,
      previousStartDate: null,
      identifierError: false
    };
  },

  mounted() {
    this.device = this.deviceData;
    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize); // Remove event listener on component destruction
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    manufacturerId(newValue, oldValue) {},
    selectedDate: {
      handler(newVal) {
        // this.callMyApiCheck();
      },
      deep: true
    }
  },

  methods: {
    toggleFilterCardVisibility() {
      this.showhistoryFiltermenu = !this.showhistoryFiltermenu;
    },
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 769) {
        this.showhistoryFiltermenu = true; // Show button
      } else {
        this.showhistoryFiltermenu = false; // Hide button
      }
    },
    searchAccount(filterAccount) {
      this.getChildAccounts(filterAccount);
    },

    goBack() {
      this.$router.go(-1);
    },
    async fetchOptions() {
      if (this.isFetchingOptions) return;
      this.isFetchingOptions = true;
      this.showLoading = true;
      let response = await new AccountService().getChildAccounts({
        isChild: true,
        page: this.currentPage,
        page_size: parseInt(this.perPage),
        filterAccount: this.filterAccount || undefined
      });
      this.showLoading = false;
      if (response && response.data) {
        this.getAllAccountOptions = response.data;
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.isFetchingOptions = false;
    },

    callMyApiPart(data) {
      this.perPage = data.perPage || 10;
      this.currentPage = data.currentPage || 1;
      this.callMyApi();
    },

    clearStartEndDate() {
      if (this.selectedDate.endDate || this.selectedDate.startDate) {
        this.selectedDate.endDate = this.selectedDate.startDate = null;
        this.callMyApi();
      }
    },
    callMyApiCheck() {
      if (
        this.selectedDate.startDate == this.previousStartDate &&
        this.selectedDate.endDate == this.previousEndDate
      ) {
        return;
      } else {
        this.callMyApi();
      }
    },
    async callMyApi() {
      try {
        this.showLoading = true;
        this.allocationHistory = [];
        let response =
          this.$route.params.id &&
          (await new DeviceService().deviceAllocationHistory({
            device_id: this.$route.params.id,
            start_date: this.selectedDate.startDate
              ? this.selectedDate.startDate
              : null,
            end_date: this.selectedDate.endDate
              ? this.selectedDate.endDate
              : null,
            page: this.currentPage,
            page_size: parseInt(this.perPage)
          }));
        this.previousEndDate = this.selectedDate.endDate;
        this.previousStartDate = this.selectedDate.startDate;
        this.showLoading = false;
        if (
          response &&
          response.data &&
          response.data.deviceAllocationHistory &&
          response.data.deviceAllocationHistory.length
        ) {
          response.data.deviceAllocationHistory.map((h) => {
            let history = {
              account: h.account,
              start_date: h.start_date,
              end_date: h.end_date ? h.end_date : ""
            };
            this.allocationHistory.push(history);
          });
          // this.allocationHistory =
          //   (response.data.history && response.data.history[0]) || [];
          this.singleHistory =
            (Array.isArray(this.allocationHistory) &&
              this.allocationHistory.length && [this.allocationHistory[0]]) ||
            [];
          this.totalDevices = (response.data.count && response.data.count) || 0;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    resetPrev() {
      this.deviceOwnerId = this.oldDeviceOwnerId;
    },

    onCloseModel() {
      this.$bvModal.hide("is-device-allocated-unit");
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.history-outer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .col-md-2 {
    padding-left: 1rem !important;
  }
}

.history-al-outer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .col-md-2 {
    padding-left: 1rem !important;
  }
}

.history-date {
  margin-right: 8px;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .add-device-align {
    padding: 10px;
  }
}

.btn-s {
  width: 48%;
}

@media screen and (min-width: 769px) {
  .desktop-hidden,
  .filter-card {
    display: none !important;
  }
}

@media screen and (max-width: 769px) {
  .d-hide-s {
    display: none !important;
  }
}

.filter-card {
  right: 1rem;
}

.filter-button {
  margin-top: 15px;
}

.tab-content .card-body {
  min-height: calc(100vh - 219px) !important;
}
</style>
<style lang="scss">
.new-date-picker-25 {
  // .daterangepicker.ltr.show-calendar.single.openscenter.linked {
  //   left: 0 !important;
  //   transform: translate(0%) !important;
  // }
}
</style>
<!-- <style lang="scss">
.scroll-history {
  height: calc(100vh - 350px) ;
  .table-responsive {
  height: calc(100vh - 388px) ;
  overflow-y: auto;
}
}
@media screen and (max-width: 769px) {
.scroll-history {
  height: calc(100vh - 300px) ;
  .table-responsive {
  height: calc(100vh - 350px) ;
  overflow-y: auto;
}
}
}
</style> -->
